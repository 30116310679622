<template>
  <div class="box">
    <div class="bj">
      <div class="w128">
        <div class="Breadcrumb">
          <span @click="goindex" style="cursor: pointer">全部课程</span> >
          <span @click="goindex" style="cursor: pointer">行业分类</span>
          <span @click="goback" style="cursor: pointer">{{
            $route.query.type
          }}</span>
          >
          {{ details.class.title }}
        </div>
        <div class="content">
          <div class="left">
            <img :src="details.class.cover" alt="" />
          </div>
          <div class="rigth">
            <div class="title">
              {{ details.class.title }}
            </div>
            <div class="class">
              ￥{{ details.class.price }}<span>元</span>

              <span class="span" v-if="details.boutique == 1">自主课</span>
              <span class="span" v-if="details.boutique == 0">官方课</span>
            </div>

            <div class="list">
              <div @click="loves">
                <img v-if="!love" src="../../assets/shoucang_icon.png" alt="" />
                <img
                  v-else
                  src="../../assets/yishoucang_icon.png"
                  alt=""
                />收藏此课
              </div>
              <div @click="fx">
                <img src="../../assets/fenxiang-icon.png" alt="" />分享此课
              </div>
              <div @click="jskt">
                <img src="../../assets/shoujiketang_icon.png" alt="" />手机课堂
              </div>
            </div>
            <div class="people">共 {{ details.class.class_hour }}课时</div>

            <div class="confirm btn" @click="zf">购买课程</div>
          </div>
        </div>
        <div class="content1">
          <div class="left">
            <div class="tablist">
              <div
                @click="active = 1"
                :class="active == 1 ? 'item active' : 'item'"
                style="margin-left: 50px"
              >
                课程概述
              </div>
              <div
                @click="active = 2"
                :class="active == 2 ? 'item active' : 'item'"
              >
                课程目录
              </div>
              <div
                @click="active = 3"
                :class="active == 3 ? 'item active' : 'item'"
              >
                答疑
              </div>
            </div>
            <div v-if="active == 1" class="module1">
              <div
                v-if="details.class.profiles == ''"
                style="text-align: center; font-size: 14px"
              >
                暂无课程描述
              </div>
              <div v-else v-html="details.class.profiles"></div>
            </div>
            <div v-if="active == 2" class="module2">
              <div v-if="details.chapter.length > 0">
                <div
                  class="item"
                  v-for="(item, index) in details.chapter"
                  :key="index"
                >
                  <div>{{ item.title }}</div>
                </div>
              </div>
              <div v-else style="text-align: center; font-size: 14px">
                暂无课程目录
              </div>
            </div>
            <div v-if="active == 3" class="module3">
              <div class="list1" v-if="details.answer.length > 0">
                <div
                  class="item"
                  v-for="(item, index) in details.answe"
                  :key="index"
                >
                  <div class="quiz">
                    <div class="quiz_1">提问:</div>
                    <div class="quiz_2">
                      {{ item.question }}
                    </div>
                  </div>
                  <div class="quiztime">
                    &nbsp;&nbsp;提问时间：<span>{{ item.time }}</span>
                  </div>
                  <div class="answer">
                    <div class="answer_1">回答:</div>
                    <div class="answer_2">
                      {{ item.answering ? item.answering : "暂无回答!" }}
                    </div>
                  </div>
                  <div class="quiztime">
                    &nbsp;&nbsp;回答时间：<span>{{
                      item.answer_time ? item.answer_time : ""
                    }}</span>
                  </div>
                </div>
              </div>
              <div v-else style="text-align: center; font-size: 14px">
                暂无答疑
              </div>
            </div>
          </div>
          <div class="rigth">
            <div class="title">学校介绍</div>
            <div class="content11">
              <div class="box3">
                <span v-html="details.school.profiles || '暂无介绍'"></span>
              </div>
              <div class="title">老师信息</div>
              <div class="box4">
                <div class="box4_4">
                  <img src="../../assets/morentouxiang_icon.png" alt="" />
                  <div>
                    <div
                      style="
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #343434;
                      "
                    >
                      老师名称：
                    </div>
                    <div
                      style="
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #137cfb;
                      "
                    >
                      {{ details.teacher.name || "暂无信息" }}
                    </div>
                  </div>
                </div>
                <div class="box4_41">
                  <div>
                    <div>
                      <img src="../../assets/shouji_icon.png" alt="" />老师手机
                    </div>
                    <div
                      style="
                        margin-top: 16px;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #137cfb;
                      "
                    >
                      {{ details.teacher.phone || "暂无信息" }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <img src="../../assets/weixin_icon.png" alt="" />老师微信
                    </div>
                    <div
                      style="
                        margin-top: 16px;

                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #137cfb;
                      "
                    >
                      {{ details.teacher.phone || "暂无信息" }}
                    </div>
                  </div>
                </div>
                <!-- <div class="btn">课程答疑</div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="divform"></div>
    <div id="alipay_submit"></div>
    <!-- //支付 -->
    <el-dialog
      top="45vh"
      title="支付"
      :visible.sync="dialogVisible"
      width="30%"
      center
    >
      <div class="zflist">
        <div class="item" @click="gg(1)">
          <img src="../../assets/weixin_zhifu_icon.png" alt="" /> 微信支付
        </div>
        <div class="item" @click="gg(2)">
          <img src="../../assets/zhifubao_icon.png" alt="" /> 支付宝支付
        </div>
      </div>
      <div class="code">
        <div class="qrcode" id="qrcode" ref="qrCodeUrl"></div>
      </div>
      <div style="height: 170px"></div>
      <div style="text-align: center">若支付成功请刷新页面</div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      dialogVisible: false,
      active: 1,
      details: {},
      chapter: [],
      love: false,
      flag: 1,
      qrcode: null,
      code_url: "",
      sss: "1212121",
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  methods: {
    ...mapActions([
      "getCancelCollection",
      "getAddCollection",
      "getclassDetail",
      "getFreePurchase",
      "getpcGenerateOrder",
      "getclassDetail_x",
      "getremoveCollection_x",
      "getinsCollection_x",
    ]),
    //分享
    fx() {
      let url = window.location.href;
      var temp = document.createElement("textarea");
      temp.value = url;
      document.body.appendChild(temp);
      temp.select(); // 选择对象
      document.execCommand("Copy");
      this.$message({
        message: "链接已复制",
        type: "success",
      });
    },
    // 手机链接
    jskt() {
      var temp = document.createElement("textarea");
      temp.value = `https://mp.zpton.com/drillDetails?id${this.details.id}&title=${this.details.title}&pic=${this.details.pic}&short=${this.details.short}&cost=${this.details.cost}&ks=${this.details.ks}&professional=${this.details.professional}&payment=${this.details.payment}&position=${this.details.position}&stu=${this.details.stu}&type=${this.details.type}`;
      document.body.appendChild(temp);
      temp.select(); // 选择对象
      document.execCommand("Copy");
      this.$message({
        message: "链接已复制前往手机打开",
        type: "success",
      });
    },
    zf() {
      this.dialogVisible = true;
    },
    // 二维码支付
    gg(order_type) {
      let that = this;
      if (this.flag != 1) {
      }
      this.getpcGenerateOrder({
        username: this.userInfo.username,
        classid: this.details.class ? this.details.class : this.details.id,
        id: this.details.class ? this.details.id : "",
        wx_code: "",
        order_type: order_type,
        type: this.payType ? this.payType : 1,
        cost: this.details.cost,
      }).then((res) => {
        if (res.code == 200) {
          that.dialogVisible = true;
          if (order_type == 1) {
            if (that.flag == 1) {
              that.qrcode = new QRCode(that.$refs.qrCodeUrl, {
                text: res.data.code_url,
                width: 150,
                height: 150,
                colorDark: "#000000",
                colorLight: "#ffffff",
                correctLevel: QRCode.CorrectLevel.H,
              });
            }
            that.flag = 2;
          }
        }
        if (order_type == 2) {
          let routerData = this.$router.resolve({
            path: "/applyText",
            query: {
              htmls: res,
            },
          });
          window.open(routerData.href, "_blank");
          const div = document.createElement("div");
          div.innerHTML = htmls;
          document.body.appendChild(div);
          document.forms[0].submit();
        }
      });
    },
    //去学习
    studyBack(val) {
      this.$store.commit("SET_CLASSID", this.$route.query.id);
      this.$store.commit("SET_STUDYTYPE", val);
      this.$router.push("/Layoutstudy/studyLecture");
    },
    //去学习
    studyBack1(val) {
      this.$store.commit("SET_CLASSID", this.$route.query.id);
      this.getFreePurchase({
        code: "",
        class: this.$route.query.id,
        username: this.userInfo.username,
      }).then((res) => {
        if (res.code == 200) {
          this.$store.commit("SET_STUDYTYPE", val);
          this.$router.push("/Layoutstudy/studyLecture");
        }
      });
    },
    // 收藏
    loves() {
      if (this.love) {
        this.getremoveCollection_x({
          id: this.details.class.id,
        }).then((res) => {
          this.$message({
            message: res.message,
            type:
              res.successCode == 200 || res.successCode == 0
                ? "success"
                : "cancel",
          });
          if (res.successCode == 200) {
            this.love = false;
          }
        });
      } else {
        this.getinsCollection_x({
          student_id: this.userInfo.id, //学员id
          class_id: this.details.class.id, //班级id
        }).then((res) => {
          this.$message({
            message: res.message,
            type:
              res.successCode == 200 || res.successCode == 0
                ? "success"
                : "cancel",
          });

          if (res.successCode == 200 || res.successCode == 0) {
            this.love = true;
          }
        });
      }
    },
    // /返回上一页
    goback() {
      this.$router.go(-1);
    },
    // 查看全部课程
    goindex() {
      this.$router.push("/training");
    },
  },
  mounted() {
    // this.getclassDetail({
    //   id: this.$route.query.id, //班级id
    // });
    this.getclassDetail_x({
      id: this.$route.query.id, //班级id
    }).then((res) => {
      if (res.successCode == 200) {
        this.details = res.responseBody;
        this.love = res.responseBody.collection == 0 ? false : true;
      }
    });
    // this.getCatalog({
    //   username: this.userInfo.username,
    //   classid: this.$route.query.id,
    //   userid: this.userInfo.id,
    // }).then((res) => {
    //   if (res.code == 200) {
    //     this.chapter = res.data.chapter;
    //   }
    // });
  },
};
</script>

<style lang="scss" scoped>
.bj {
  overflow: hidden;

  background: url("../../assets/beijingtu.png");
}
.w128 {
  width: 1280px;
  margin: 0 auto;
}
.Breadcrumb {
  margin: 31px 0 14px 0;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6c6c6c;
}
.zflist {
  display: flex;
  justify-content: space-around;
  .item {
    img {
      width: 40px;
      height: 40px;
      vertical-align: middle;
    }
    cursor: pointer;
    width: 170px;
    height: 50px;
    font-size: 16px;
    text-align: center;
    line-height: 50px;
    border: 1px solid #e1e1e1;
  }
}
.code {
  position: relative;
  .qrcode {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
.content {
  display: flex;
  .left {
    margin-right: 19px;
    width: 720px;
    height: 430px;
    img {
      width: 720px;
      height: 430px;
    }
  }
  .rigth {
    width: 541px;
    position: relative;
    .title {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
    }
    .class1 {
      margin-top: 13px;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;

      color: #137cfb;
    }
    .class {
      margin-top: 13px;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;

      color: #ff4300;
      .span {
        color: #aeaeae;
      }
    }
    .list {
      margin-top: 39px;
      display: flex;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6c6c6c;
      div {
        cursor: pointer;
        margin-right: 64px;
      }
      img {
        vertical-align: middle;
        margin-right: 3px;
      }
    }
    .list1 {
      .item {
        margin-top: 45px;
        border-bottom: 1px solid #e1e1e1;
        padding-bottom: 15px;
        .quiz {
          display: flex;
          width: 400px;
          line-height: 18px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          .quiz_1 {
            padding: 2px 5px;
            background-color: #137cfb;
            color: #fff;
          }
          .quiz_2 {
            flex: 1;
            color: #7d7d7d;
            margin-left: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .quiz_3 {
            cursor: pointer;
            padding: 2px 5px;
            background-color: #137cfb;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
        }
        .quiztime {
          margin-top: 10px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #7d7d7d;
          span {
            color: #cacaca;
          }
        }
        .answer {
          margin-top: 10px;
          display: flex;
          line-height: 16px;
          font-size: 14px;
          .answer_1 {
            padding: 2px 5px;
            background-color: #d9eaff;
            color: #137cfb;
            line-height: 14px;
            font-size: 14px;
          }
          .answer_2 {
            margin-left: 5px;
            color: #7d7d7d;
          }
        }
      }
    }
    .people {
      margin-top: 30px;
      padding-left: 18px;
      box-sizing: border-box;
      height: 50px;
      background: #f6f6f6;
      line-height: 50px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6c6c6c;
    }
    .btn {
      cursor: pointer;
      position: absolute;
      bottom: 0;
      height: 60px;
      line-height: 60px;
      text-align: center;
      color: #fff;
      width: 100%;
      background: #137cfb;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
.content1 {
  display: flex;
  justify-content: space-between;
  .left {
    width: 885px;
    .tablist {
      display: flex;
      line-height: 66px;
      width: 885px;
      height: 66px;
      background: #ffffff;
      border-bottom: 1px solid #e1e1e1;
      .item {
        cursor: pointer;
        position: relative;
        margin-right: 118px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #222222;
      }
      .active {
        &::before {
          position: absolute;
          content: "";
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 96px;
          height: 4px;
          background: #137cfb;
        }
      }
    }
  }
  .rigth {
    width: 376px;
    .title {
      height: 66px;
      line-height: 66px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
      background: #ffffff;
      border-bottom: 1px solid #e1e1e1;
    }
    .content11 {
      // padding: 0 27px;
      box-sizing: border-box;
      .box1 {
        display: block;
        margin-top: 13px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #222222;
        img {
          margin-right: 8px;
          vertical-align: middle;
        }
      }
      .box2 {
        margin-top: 48px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #6c6c6c;
        display: flex;
        justify-content: space-around;
        img {
          vertical-align: middle;
          margin-right: 10px;
        }
      }
      .box3 {
        margin-top: 10px;
        margin-bottom: 150px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #919191;
      }
      .box4 {
        margin-top: 23px;
        .box4_4 {
          display: flex;
          img {
            margin-right: 10px;
            width: 50px;
            height: 50px;
          }
        }
        .box4_41 {
          margin-bottom: 150px;
          margin-top: 33px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #6c6c6c;
          display: flex;
          justify-content: space-around;
          img {
            margin-right: 8px;
            vertical-align: middle;
          }
        }
        .btn {
          margin-top: 27px;
          margin-bottom: 82px;
          text-align: center;
          line-height: 39px;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #6c6c6c;
          height: 39px;
          background: #d8d8d8;
        }
      }
    }
  }
}
::v-deep .module1 {
  padding: 39px;
  box-sizing: border-box;
  width: 100%;
  img {
    display: block;
    width: 100%;
  }
}
.module2 {
  padding: 50px;
  box-sizing: border-box;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #343434;
  .item {
    width: 594px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
}
.module3 {
  padding: 50px;
  box-sizing: border-box;
  .item {
    margin-bottom: 45px;
    img {
      margin-right: 19px;
      width: 35px;
      height: 35px;
      vertical-align: middle;
    }
    .item_11 {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
